import { buildQuery, buildQueryKey, graphql, runGraphqlQuery } from "@/graphql";

const query = graphql(/* GraphQL */ `
  query DashboardRecommendations {
    recommendations {
      ...dashboardRecommendation
    }
  }
`);

graphql(/* GraphQL */ `
  fragment dashboardRecommendation on Recommendation {
    id
    type
    adSetId
    performanceCampaign {
      id
      name
      adSets {
        id
        name
      }
      app {
        ...app
      }
    }
    creationDate
    updateDate
  }
`);

export function getQueryOptions() {
  return {
    queryKey: buildQueryKey(query),
    async queryFn() {
      return runGraphqlQuery(query);
    },
  };
}

export function useDashboardRecommendationsQuery() {
  return buildQuery({
    ...getQueryOptions(),
  });
}
