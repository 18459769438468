import { buildQuery, buildQueryKey, graphql, runGraphqlQuery } from "@/graphql";

const query = graphql(/* GraphQL */ `
  query DashboardStats {
    performanceDraft: performanceCampaigns(status: [DRAFT]) {
      __typename
      totalCount
    }

    performancePending: performanceCampaigns(status: [PENDING]) {
      __typename
      totalCount
    }

    performanceRunning: performanceCampaigns(status: [RUNNING]) {
      __typename
      totalCount
    }

    performanceStopped: performanceCampaigns(status: [STOPPED]) {
      __typename
      totalCount
    }

    brandingDraft: brandingCampaigns(status: [DRAFT]) {
      __typename
      totalCount
    }

    brandingPending: brandingCampaigns(status: [PENDING]) {
      __typename
      totalCount
    }

    brandingRunning: brandingCampaigns(status: [RUNNING]) {
      __typename
      totalCount
    }

    brandingStopped: brandingCampaigns(status: [STOPPED]) {
      __typename
      totalCount
    }
  }
`);

function getQueryOptions() {
  return {
    queryKey: buildQueryKey(query),
    async queryFn() {
      return runGraphqlQuery(query);
    },
  };
}

export function useDashboardStatsQuery() {
  return buildQuery({
    ...getQueryOptions(),
  });
}
