import { useMutation, useQueryClient } from "@tanstack/vue-query";

import { graphql, runGraphqlQuery } from "@/graphql";
import type { RecommendationMarkAsReadMutationVariables } from "@/graphql";

import * as dashboardRecommendationQuery from "../queries/dashboard-recommendations.query.graphql";

const mutation = graphql(/* GraphQL */ `
  mutation RecommendationMarkAsRead($recommendationId: ID!) {
    markRecommendationAsRead(id: $recommendationId) {
      success
    }
  }
`);

export function useRecommendationMarkAsRead() {
  const queryClient = useQueryClient();

  return useMutation({
    async mutationFn(variables: RecommendationMarkAsReadMutationVariables) {
      const result = await runGraphqlQuery(mutation, variables);
      return result?.markRecommendationAsRead;
    },
    onSuccess() {
      const { queryKey } = dashboardRecommendationQuery.getQueryOptions();
      void queryClient.invalidateQueries({ queryKey });
    },
  });
}
